<script setup lang="ts">
import { contactData } from "~/state/state";

defineEmits(["update:modelValue"]);
const props = defineProps({ modelValue: Boolean });

const contactList = contactData.toArray;

watch(
    () => props.modelValue,
    () => {
        const htmlEl: HTMLElement | null = document.querySelector("html");
        if (!htmlEl) return;
        htmlEl.style.overflow = props.modelValue ? "hidden" : "auto";
    }
);
</script>

<template>
    <VThemeProvider theme="dark" with-background>
        <div :class="{ 'contact-panel-overlay': true, 'is-visible': modelValue }" role="none" @click="$emit('update:modelValue', !modelValue)"></div>
        <aside :class="['px-5 py-10 pt-5 font-weight-thin', { 'contact-panel-wrapper': true, 'is-visible': modelValue }]">
            <span class="close-btn-wrapper">
                <VBtn
                    icon="$close"
                    class="close-btn"
                    color="white"
                    variant="text"
                    size="small"
                    aria-label="Close"
                    title="Close"
                    @click="$emit('update:modelValue', !modelValue)"
                ></VBtn>
            </span>

            <VRow no-gutters class="text-center" justify="center">
                <VCol cols="7">
                    <NuxtLink to="/">
                        <Svg name="allmedica-logo-vertical" role="link" color="white"></Svg>
                    </NuxtLink>
                </VCol>
            </VRow>

            <h3 class="my-2 mt-sm-4">
                <DecoratedTitle
                    dark
                    class-name="text-center text-h6 text-sm-h5 text-md-h4 text-md-0"
                    text="Contact us"
                    decorated-phrase="us"
                    append-icon="star-2"
                    append-icon-size=".6em"
                    append-icon-position="100% -100%"
                    append-icon-animation-class="rotate"
                />
            </h3>
            <VRow>
                <VCol v-for="(item, i) in contactList" :key="i" cols="12" class="px-md-0 px-lg-3">
                    <ContactItem
                        in-aside
                        :icon="item.icon"
                        :title="item.title"
                        :text="item.text"
                        :url="item.url"
                        :color="item.color.light"
                        :target="item.target"
                    />
                </VCol>
            </VRow>
            <SocialWrapper :items="contactData.social" justify="center"></SocialWrapper>
        </aside>
    </VThemeProvider>
</template>

<style lang="scss" scoped>
.contact-panel-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 92%;
    max-width: 30rem;
    z-index: 1010;
    transform: translateX(100%);
    transition: 300ms ease-in-out transform;
    overflow-y: auto;
    background: url("/img/blob-bg.svg"), #0c0c0c;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    & > div {
        padding: 1.25rem 0.75rem;
    }
    &.is-visible {
        transform: translateX(0);
    }

    .close-btn-wrapper {
        .close-btn {
            position: sticky;
            top: 0;
            left: 100%;
        }
    }

    @media (width > 960px) {
        & > div {
            padding: 1.25rem;
        }
    }

    @media (width > 1280px) {
        & > div {
            padding: 2.5rem;
        }
    }
}

.contact-panel-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: black;
    opacity: 0;
    pointer-events: none;
    z-index: 1010;
    transition: opacity 200ms ease-in-out;

    &.is-visible {
        pointer-events: all;
        opacity: 0.7;
    }
}
</style>
